import React, { useState } from "react";
import "./docutracker.css";
import Allservices from "../Allservices";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import Footer from "../../Homepage/Footer";
import { Grid } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { withStyles } from "@material-ui/core/styles";
import SendPackageStyle from "../Package/SendPackageStyle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import Select from "@mui/material/Select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@mui/icons-material/Check";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import DashboardIot from "./DashboardIot";
import IotConfiguration from "../Iot-configuration/iot-configuration";
import { getIotUrl } from "../../../api/apiManagement";
import CompletedDocuments from "./CompletedDocuments";


const blue = {
  50: "#131C4C",
  100: "#C2E0FF",
  200: "#FFFFFF",
  300: "#66B2FF",
  400: "#EBE3CA",
  500: "#EBE3CA",
  600: "#FFFFFF",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};
const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #131c4c;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: ${blue[50]};
  color: ${blue[200]};

  border: none;
  border-radius: 20px;

  display: flex;
  border-radius: 60px;
  &:hover {
    background-color: ${blue[900]};
  }

  &:focus {
    color: #fff;
    font-weight: 700;
    border-radius: 60px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[600]};
    color: ${blue[50]};
    border-radius: 60px;
  }
  &.${tabUnstyledClasses} {
    background-color: ${blue[50]};
    color: ${blue[50]};
    border-radius: 60px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    border-radius: 60px;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  height: auto;

  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
`;

const green = {
  500: "#000000",
};

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const Root = styled("span")(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 75px;
  height: 35px;
  margin: 0px 15px;
  cursor: pointer;
  margin-top: 10px;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
    border-radius: 29px;
    display: block;
    height: 35px;
    width: 75px;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 29px;
    height: 29px;
    top: 3px;
    left: 5px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 41px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #131C4C;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
);

function Documenttracker(props) {
  let iotDocument = getIotUrl() + "/api/document/";
  const { classes } = props;
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = React.useState(false);
  const [iotDetails, setIotDetails] = React.useState();


  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 6,
      }}
    />
  );
  function gotoIOT() {
    navigate("/iotconfig");
  }
  let iotUrlStaff = getIotUrl() + "/api/staff/";
  const iotUrl = getIotUrl() + "/api/devices/";
  React.useEffect(() => {
    axios.get(iotUrlStaff, {}).then((response) => {
      if (response && response.data && response.data.staff_info) {
        console.log("xxxxx", response.data.staff_info);
        setstaffSet(response.data.staff_info);
      }
    });
  }, [iotUrlStaff]);
  React.useEffect(() => {
    axios.get(iotUrl, {

  }).then((response) => {
    if(response){
      console.log("rrrrr",response)
      setIotDetails(response.data.device_info.filter((device)=> device.device_status === 'available'))
    }
      //console.log("tttt",response.data.device_info.filter((device)=> device.device_status === 'Available'))

  });
  }, [iotUrl]);
  const resetDocument = () => {
    debugger;
    setInputFieldsCompany([]);
    setInputFields([]);
    setInputFieldsBirthCertificate([]);
    setInputFieldsDeathCertificate([]);
    setInputFieldsDegreeCertificate([]);
    setInputFieldsEmployment([]);
    setInputFieldsLabourContract([]);
    setInputFieldsMarriageCertificate([]);
    setInputFieldsNOC([]);
    setInputFieldsOfferLetter([]);
    setClientName("");
    setclientPhone("");
    setAddress(null);
    setDeviceNumber("");
    setstaffNames("")
  }
  const submitDocument = () => {
    const documentArray = [];
    //const mergedArray = [...inputFields,...inputFieldsCompany,inputFieldsDegreeCertificate,inputFieldsBirthCertificate,inputFieldsDeathCertificate,inputFieldsEmployment,inputFieldsLabourContract,inputFieldsMarriageCertificate,inputFieldsNOC,inputFieldsOfferLetter]
    if (inputFields && inputFields.length) {
      for (let x of inputFields) {
        documentArray.push(x);
      }
    }
    if (inputFieldsCompany && inputFieldsCompany.length) {
      for (let x of inputFieldsCompany) {
        documentArray.push(x);
      }
    }
    if (inputFieldsDegreeCertificate && inputFieldsDegreeCertificate.length) {
      for (let x of inputFieldsDegreeCertificate) {
        documentArray.push(x);
      }
    }
    if (inputFieldsBirthCertificate && inputFieldsBirthCertificate.length) {
      for (let x of inputFieldsBirthCertificate) {
        documentArray.push(x);
      }
    }
    if (inputFieldsDeathCertificate && inputFieldsDeathCertificate.length) {
      for (let x of inputFieldsDeathCertificate) {
        documentArray.push(x);
      }
    }
    if (inputFieldsEmployment && inputFieldsEmployment.length) {
      for (let x of inputFieldsEmployment) {
        documentArray.push(x);
      }
    }
    if (inputFieldsLabourContract && inputFieldsLabourContract.length) {
      for (let x of inputFieldsLabourContract) {
        documentArray.push(x);
      }
    }
    if (
      inputFieldsMarriageCertificate &&
      inputFieldsMarriageCertificate.length
    ) {
      for (let x of inputFieldsMarriageCertificate) {
        documentArray.push(x);
      }
    }
    if (inputFieldsNOC && inputFieldsNOC.length) {
      for (let x of inputFieldsNOC) {
        documentArray.push(x);
      }
    }
    if (inputFieldsOfferLetter && inputFieldsOfferLetter.length) {
      for (let x of inputFieldsOfferLetter) {
        documentArray.push(x);
      }
    }
    setSubmitted(!submitted);
    console.log("gg", documentArray);
    let obj = {
      documents: documentArray,
      client_name: clientName,
      client_phone: clientPhone,
      client_address: address,
      iot_device: parseInt(deviceNumber),
      iot_device_number: parseInt(deviceNumber),
      staff: staffNames,
      user_id:localStorage.getItem("userId")
    };
    axios.post(iotDocument, obj).then((res) => {
      console.log(res.data);
      if (res.data.status === "success") {
        console.log(res);
        window.location.reload();
      }
      try {
      } catch (error) {
     alert.error()
      }
    });

  };
  const [value, setValue] = React.useState(0);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [staffNames, setstaffNames] = React.useState(null);
  const [staffSet, setstaffSet] = React.useState([]);
  const [clientName, setClientName] = React.useState("");
  const [clientPhone, setclientPhone] = React.useState("");
  const [deviceNumber, setDeviceNumber] = React.useState("");

  const handleClientName = (event) =>{
    setClientName(event.target.value)
  }
  const handleClientPhone = (event) =>{
    setclientPhone(event.target.value)
  }
  const handleClientDevice = (event) =>{
    setDeviceNumber(event.target.value.id)
  }


  const handleChangestaffNames = (event) => {
    setstaffNames(event.target.value.id);
    console.log("gg", event.target.value.name);
  };
  const [address, setAddress] = React.useState(null);

  const [inputFields, setInputFields] = React.useState([]);
  const [inputFieldsCompany, setInputFieldsCompany] = React.useState([]);

  const [inputFieldsDegreeCertificate, setInputFieldsDegreeCertificate] =
    React.useState([]);
  const [inputFieldsMarriageCertificate, setInputFieldsMarriageCertificate] =
    React.useState([]);
  const [inputFieldsBirthCertificate, setInputFieldsBirthCertificate] =
    React.useState([]);
  const [inputFieldsDeathCertificate, setInputFieldsDeathCertificate] =
    React.useState([]);

  const [inputFieldsEmployment, setInputFieldsEmployment] = React.useState([]);
  const [inputFieldsLabourContract, setInputFieldsLabourContract] =
    React.useState([]);
  const [inputFieldsOfferLetter, setInputFieldsOfferLetter] = React.useState(
    []
  );
  const [inputFieldsNOC, setInputFieldsNOC] = React.useState([]);

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      { document_type: "Passport", document_name: "", document_id: "",document_description: "Desc" },
    ]);
    //alert(JSON.stringify(inputFields.length))
  };
  const handleRemove = () => {
    const values = [...inputFields];
    values.pop();
    setInputFields(values);
  };
  const handleAddCompany = () => {
    setInputFieldsCompany([
      ...inputFieldsCompany,
      { document_type: "CompanyID", document_id: "", document_name: "",document_description: "Desc"  },
    ]);
    console.log(inputFields);
  };
  const handleRemoveCompany = () => {
    const values = [...inputFieldsCompany];
    values.pop();
    setInputFieldsCompany(values);
  };

  const handleRemoveDegreeCertificate = () => {
    const values = [...inputFieldsDegreeCertificate];
    values.pop();
    setInputFieldsDegreeCertificate(values);
  };
  const handleAddDegreeCertificate = () => {
    setInputFieldsDegreeCertificate([
      ...inputFieldsDegreeCertificate,
      { document_type: "DegreeCertificate", document_description: "" },
    ]);
  };

  const handleRemoveMarriageCertificate = () => {
    const values = [...inputFieldsMarriageCertificate];
    values.pop();
    setInputFieldsMarriageCertificate(values);
  };
  const handleAddMarriageCertificate = () => {
    setInputFieldsMarriageCertificate([
      ...inputFieldsMarriageCertificate,
      { document_type: "MarriageCertificate", document_description: "" },
    ]);
  };

  const handleRemoveBirthCertificate = () => {
    const values = [...inputFieldsBirthCertificate];
    values.pop();
    setInputFieldsBirthCertificate(values);
  };
  const handleAddBirthCertificate = () => {
    setInputFieldsBirthCertificate([
      ...inputFieldsBirthCertificate,
      { document_type: "BirthCertificate", document_description: "" },
    ]);
  };

  const handleRemoveDeathCertificate = () => {
    const values = [...inputFieldsDeathCertificate];
    values.pop();
    setInputFieldsDeathCertificate(values);
  };
  const handleAddDeathCertificate = () => {
    setInputFieldsDeathCertificate([
      ...inputFieldsDeathCertificate,
      { document_type: "DeathCertificate", document_description: "" },
    ]);
  };

  const handleRemoveEmployment = () => {
    const values = [...inputFieldsEmployment];
    values.pop();
    setInputFieldsEmployment(values);
  };
  const handleAddEmployment = () => {
    setInputFieldsEmployment([
      ...inputFieldsEmployment,
      { document_type: "Employment", document_description: "" },
    ]);
  };

  const handleRemoveLabourContract = () => {
    const values = [...inputFieldsLabourContract];
    values.pop();
    setInputFieldsLabourContract(values);
  };
  const handleAddLabourContract = () => {
    setInputFieldsLabourContract([
      ...inputFieldsLabourContract,
      { document_type: "LabourContract", document_description: "" },
    ]);
  };

  const handleRemoveOfferLetter = () => {
    const values = [...inputFieldsOfferLetter];
    values.pop();
    setInputFieldsOfferLetter(values);
  };
  const handleAddOfferLetter = () => {
    setInputFieldsOfferLetter([
      ...inputFieldsOfferLetter,
      { document_type: "OfferLetter", document_description: "" },
    ]);
  };

  const handleRemoveNOC = () => {
    const values = [...inputFieldsNOC];
    values.pop();
    setInputFieldsNOC(values);
  };
  const handleAddNOC = () => {
    setInputFieldsNOC([
      ...inputFieldsNOC,
      { document_type: "NOC", document_description: "" },
    ]);
  };

  const handlePassportName = (event, val) => {
    inputFields[val].document_name = event.target.value;
  };
  const handlePassportNumber = (event, val) => {
    inputFields[val].document_id = event.target.value;
  };

  const handleComapnyName = (event, val) => {
    inputFieldsCompany[val].document_id = event.target.value;
  };
  const handleCompanyNameID = (event, val) => {
    inputFieldsCompany[val].document_name = event.target.value;
  };
  const handleBirthDesc = (event, val) => {
    inputFieldsBirthCertificate[val].document_description = event.target.value;
  };
  const handleDegreeDesc = (event, val) => {
    inputFieldsDegreeCertificate[val].document_description = event.target.value;
  };
  const handleMarriageDesc = (event, val) => {
    inputFieldsMarriageCertificate[val].document_description =
      event.target.value;
  };
  const handleDeathDesc = (event, val) => {
    inputFieldsDeathCertificate[val].document_description = event.target.value;
  };
  const handleEmpDesc = (event, val) => {
    inputFieldsEmployment[val].document_description = event.target.value;
  };
  const handleLabourDesc = (event, val) => {
    inputFieldsLabourContract[val].document_description = event.target.value;
  };
  const handleNOCDesc = (event, val) => {
    inputFieldsNOC[val].document_description = event.target.value;
  };
  const handleOfferDesc = (event, val) => {
    inputFieldsOfferLetter[val].document_description = event.target.value;
  };
  const [activeTab, setActiveTab] = React.useState(1);
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    console.log(JSON.stringify(result));
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinates(ll);
    console.log(JSON.stringify(ll));
    // geocodeByAddress(address)
    //    .then(results => getLatLng(results[0]))
    //    .then(latLng => console.log('Success', latLng))
    //   .catch(error => console.error('Error', error));
  };

  const [coordinate, setCoordinates] = React.useState({ lat: "", long: "" });
  return (
    <>
      <Allservices></Allservices>
      <Grid container className={classes.section_start}>
        <Grid container className={classes.newbgIot}>
          <Grid container className={classes.secn}>
          
            <Grid container className={classes.ht}>
            {iotDetails ?(
              <Grid container className={classes.send_bg}>
                <Grid item xs={8} md={3} lg={3}>
                  <div className={classes.heading}>
                    <div className={classes.send_heading}>Dashboard</div>
                  </div>
                </Grid>

                <Grid item xs={4} md={9} lg={9}>
                  <div className={classes.send_header}>
                    <ColoredLine color="#131C4C" />
                  </div>
                </Grid>

                <Grid xs={12} md={12} lg={12} className="tab-container">
                  <Box
                    className="tab-header"
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                  >
                    <TabsUnstyled defaultValue={0}>
                      <Tabs
                        // value={value}
                        // onChange={handlesChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className="main-tab"
                      >
                        <Tab className="tab-order">Home</Tab>&nbsp;
                        <Tab className="tab-order">Create Documents</Tab>&nbsp;
                        {/* <Tab className={classes.httab}>Stalled Documents</Tab>
                        &nbsp;
                        <Tab className={classes.httab}>Alerts</Tab>&nbsp; */}
                        {/*} <Tab >Chat Messages</Tab>*/}
                        <Tab className={classes.httab}>Iot Configuration</Tab>
                        <Tab className={classes.httab}>Completed Documents</Tab>
                        &nbsp;
                      </Tabs>

                      <TabPanel value={0}>
                        <Box className="tab-content">
                          <DashboardIot></DashboardIot>
                        </Box>
                      </TabPanel>
                      <TabPanel value={1}>
                        <Box className="tab-content">
                          <Grid xs={12} md={12} lg={12}>
                            <span className="heading">
                              Enter the below details to proceed tracking the
                              documents
                            </span>
                            <div>
                              <span className="item">Item Information</span>
                            </div>
                          </Grid>

                          <Grid container>
                            <div className={classes.content}>
                              <div>
                                <PopupState
                                  variant="contained"
                                  popupId="demo-popup-menu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <Button
                                        className={classes.document_btn}
                                        {...bindTrigger(popupState)}
                                      >
                                        Documents
                                        <img
                                          className={classes.ared}
                                          src="./Images/arrowed.svg"
                                        ></img>
                                      </Button>
                                      <Menu
                                        {...bindMenu(popupState)}
                                        className={classes.popupContainer}
                                      >
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Passport
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() => handleRemove()}
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {inputFields.length}
                                            </Button>
                                            <IconButton
                                              onClick={() => handleAdd()}
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Company ID
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveCompany()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {inputFieldsCompany.length}
                                            </Button>
                                            <IconButton
                                              onClick={() => handleAddCompany()}
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>

                                        {/*<MenuItem onClick={popupState.close}>Logout</MenuItem>*/}
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </div>
                            </div>
                            &nbsp;&nbsp; &nbsp;&nbsp;
                            <div className={classes.content}>
                              <div>
                                <PopupState
                                  variant="popover"
                                  popupId="demo-popup-menu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <Button
                                        className={classes.document_btn}
                                        {...bindTrigger(popupState)}
                                      >
                                        Certificates
                                        <img
                                          className={classes.ared}
                                          src="./Images/arrowed.svg"
                                        ></img>
                                      </Button>
                                      <Menu
                                        {...bindMenu(popupState)}
                                        className={classes.popupContainer}
                                      >
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Degree Certificate
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDegreeCertificate()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {
                                                inputFieldsDegreeCertificate.length
                                              }
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddDegreeCertificate()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Marriage Certificate
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveMarriageCertificate()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {
                                                inputFieldsMarriageCertificate.length
                                              }
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddMarriageCertificate()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Birth Certificate
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveBirthCertificate()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {
                                                inputFieldsBirthCertificate.length
                                              }
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddBirthCertificate()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        {/* <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Death Certificate
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveDeathCertificate()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {
                                                inputFieldsDeathCertificate.length
                                              }
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddDeathCertificate()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem> */}

                                        {/*<MenuItem onClick={popupState.close}>Logout</MenuItem>*/}
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </div>
                            </div>
                            &nbsp;&nbsp;
                            {/* <div className={classes.content}>
                              <div>
                                <PopupState
                                  variant="popover"
                                  popupId="demo-popup-menu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <Button
                                        className={classes.document_btn}
                                        {...bindTrigger(popupState)}
                                      >
                                        Agreement
                                        <img
                                          className={classes.ared}
                                          src="./Images/arrowed.svg"
                                        ></img>
                                      </Button>
                                      <Menu
                                        {...bindMenu(popupState)}
                                        className={classes.popupContainer}
                                      >
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Employment Cont.
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveEmployment()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {inputFieldsEmployment.length}
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddEmployment()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Labour Contract
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveLabourContract()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {inputFieldsLabourContract.length}
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddLabourContract()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        <MenuItem className="dropnew">
                                          <span className="valdet">
                                            {" "}
                                            Offer Letter
                                          </span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() =>
                                                handleRemoveOfferLetter()
                                              }
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {inputFieldsOfferLetter.length}
                                            </Button>
                                            <IconButton
                                              onClick={() =>
                                                handleAddOfferLetter()
                                              }
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>
                                        <MenuItem className="dropnew">
                                          <span className="valdet"> NOC</span>
                                          <div className="passd">
                                            <IconButton
                                              onClick={() => handleRemoveNOC()}
                                              className="valDocLeft"
                                            >
                                              <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                            <Button className="newed">
                                              {inputFieldsNOC.length}
                                            </Button>
                                            <IconButton
                                              onClick={() => handleAddNOC()}
                                              className="valDoc"
                                            >
                                              <AddIcon></AddIcon>
                                            </IconButton>
                                          </div>
                                        </MenuItem>

                                       
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </div>
                            </div> */}
                            &nbsp;&nbsp;
                            <div className={classes.content}>
                              <div>
                                <PopupState
                                  variant="popover"
                                  popupId="demo-popup-menu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <Button
                                        className={classes.document_btn}
                                        {...bindTrigger(popupState)}
                                      >
                                        Others
                                        <img
                                          className={classes.ared}
                                          src="./Images/arrowed.svg"
                                        ></img>
                                      </Button>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </div>
                            </div>
                            &nbsp;&nbsp;
                           
                          </Grid>
                          <Grid>
                            <br />
                            <Grid container>
                              <Grid item xs={12} md={12} lg={12}>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    name="mobileNo"
                                    placeholder="Others"
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                    className={classes.searchInput}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>

                            {inputFields && inputFields.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    Passport({inputFields.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFields.map((inputField, index) => (
                              <Grid container className="flexed-pass">
                                <span className="circle">{index + 1}</span>
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="namePassport"
                                      placeholder="Name in Passport"
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      defaultValue={inputField.document_name}
                                      onChange={(event) =>
                                        handlePassportName(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                &nbsp;&nbsp;
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="passportNumber"
                                      placeholder="Passport Number"
                                      aria-describedby="outlined-weight-helper-text"
                                      defaultValue={inputField.document_id}
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      onChange={(event) =>
                                        handlePassportNumber(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            ))}

                            {inputFieldsCompany && inputFieldsCompany.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    Company ID({inputFieldsCompany.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsCompany.map((inputField, index) => (
                              <Grid container className="flexed-pass">
                                <span className="circle">{index + 1}</span>
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="nameID"
                                      placeholder="Name in ID"
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      defaultValue={inputFieldsCompany.document_name}
                                      onChange={(event) =>
                                        handleComapnyName(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                &nbsp;&nbsp;
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="compID"
                                      placeholder="Company Name in ID"
                                      aria-describedby="outlined-weight-helper-text"
                                      defaultValue={inputFieldsCompany.document_id}
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      onChange={(event) =>
                                        handleCompanyNameID(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            ))}

                            {inputFieldsBirthCertificate &&
                            inputFieldsBirthCertificate.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    BirthCertificate(
                                    {inputFieldsBirthCertificate.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsBirthCertificate.map(
                              (inputField, index) => (
                                <Grid container className="flexed-pass">
                                  <span className="circle">{index + 1}</span>
                                  <Grid item xs={12} md={3} lg={3}>
                                    <FormControl
                                      fullWidth
                                      className={clsx(
                                        classes.margin,
                                        classes.textField
                                      )}
                                      variant="outlined"
                                    >
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="doc_Desc"
                                        placeholder="Name in ID"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                        labelWidth={0}
                                        className={classes.searchInput}
                                        defaultValue={inputFieldsBirthCertificate.document_description}
                                        onChange={(event) =>
                                          handleBirthDesc(event, index)
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  &nbsp;&nbsp;
                                </Grid>
                              )
                            )}

                            {inputFieldsDegreeCertificate &&
                            inputFieldsDegreeCertificate.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    DegreeCertificate(
                                    {inputFieldsDegreeCertificate.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsDegreeCertificate.map(
                              (inputField, index) => (
                                <Grid container className="flexed-pass">
                                  <span className="circle">{index + 1}</span>
                                  <Grid item xs={12} md={3} lg={3}>
                                    <FormControl
                                      fullWidth
                                      className={clsx(
                                        classes.margin,
                                        classes.textField
                                      )}
                                      variant="outlined"
                                    >
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="doc_Desc"
                                        placeholder="Name in ID"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                        labelWidth={0}
                                        className={classes.searchInput}
                                        defaultValue={inputFieldsDegreeCertificate.document_description}
                                        onChange={(event) =>
                                          handleBirthDesc(event, index)
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  &nbsp;&nbsp;
                                </Grid>
                              )
                            )}

                            {inputFieldsMarriageCertificate &&
                            inputFieldsMarriageCertificate.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    MarriageCertificate(
                                    {inputFieldsMarriageCertificate.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsMarriageCertificate.map(
                              (inputField, index) => (
                                <Grid container className="flexed-pass">
                                  <span className="circle">{index + 1}</span>
                                  <Grid item xs={12} md={3} lg={3}>
                                    <FormControl
                                      fullWidth
                                      className={clsx(
                                        classes.margin,
                                        classes.textField
                                      )}
                                      variant="outlined"
                                    >
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="doc_Desc"
                                        placeholder="Name in ID"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                        labelWidth={0}
                                        className={classes.searchInput}
                                        defaultValue={inputFieldsMarriageCertificate.document_description}
                                        onChange={(event) =>
                                          handleMarriageDesc(event, index)
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  &nbsp;&nbsp;
                                </Grid>
                              )
                            )}

                            {inputFieldsDeathCertificate &&
                            inputFieldsDeathCertificate.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    DeathCertificate(
                                    {inputFieldsDeathCertificate.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsDeathCertificate.map(
                              (inputField, index) => (
                                <Grid container className="flexed-pass">
                                  <span className="circle">{index + 1}</span>
                                  <Grid item xs={12} md={3} lg={3}>
                                    <FormControl
                                      fullWidth
                                      className={clsx(
                                        classes.margin,
                                        classes.textField
                                      )}
                                      variant="outlined"
                                    >
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="doc_Desc"
                                        placeholder="Name in ID"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                        labelWidth={0}
                                        className={classes.searchInput}
                                        defaultValue={inputFieldsDeathCertificate.document_description}
                                        onChange={(event) =>
                                          handleDeathDesc(event, index)
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  &nbsp;&nbsp;
                                </Grid>
                              )
                            )}
                            {inputFieldsEmployment &&
                            inputFieldsEmployment.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    Employment Cont.(
                                    {inputFieldsEmployment.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsEmployment.map((inputField, index) => (
                              <Grid container className="flexed-pass">
                                <span className="circle">{index + 1}</span>
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="doc_Desc"
                                      placeholder="Name in ID"
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      defaultValue={inputFieldsEmployment.document_description}
                                      onChange={(event) =>
                                        handleEmpDesc(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                &nbsp;&nbsp;
                              </Grid>
                            ))}

                            {inputFieldsLabourContract &&
                            inputFieldsLabourContract.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    Labour Contract(
                                    {inputFieldsLabourContract.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsLabourContract.map(
                              (inputField, index) => (
                                <Grid container className="flexed-pass">
                                  <span className="circle">{index + 1}</span>
                                  <Grid item xs={12} md={3} lg={3}>
                                    <FormControl
                                      fullWidth
                                      className={clsx(
                                        classes.margin,
                                        classes.textField
                                      )}
                                      variant="outlined"
                                    >
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="doc_Desc"
                                        placeholder="Name in ID"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                        labelWidth={0}
                                        className={classes.searchInput}
                                        defaultValue={inputFieldsLabourContract.document_description}
                                        onChange={(event) =>
                                          handleLabourDesc(event, index)
                                        }
                                      />
                                    </FormControl>
                                  </Grid>
                                  &nbsp;&nbsp;
                                </Grid>
                              )
                            )}

                            {inputFieldsOfferLetter &&
                            inputFieldsOfferLetter.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    Offer Letter(
                                    {inputFieldsOfferLetter.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsOfferLetter.map((inputField, index) => (
                              <Grid container className="flexed-pass">
                                <span className="circle">{index + 1}</span>
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="doc_Desc"
                                      placeholder="Name in ID"
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      defaultValue={inputFieldsOfferLetter.document_description}
                                      onChange={(event) =>
                                        handleOfferDesc(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                &nbsp;&nbsp;
                              </Grid>
                            ))}

                            {inputFieldsNOC && inputFieldsNOC.length ? (
                              <Grid container>
                                <Grid container xs={12} md={12} lg={12}>
                                  <div className="client-head">
                                    NOC(
                                    {inputFieldsNOC.length})
                                  </div>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}

                            {inputFieldsNOC.map((inputField, index) => (
                              <Grid container className="flexed-pass">
                                <span className="circle">{index + 1}</span>
                                <Grid item xs={12} md={3} lg={3}>
                                  <FormControl
                                    fullWidth
                                    className={clsx(
                                      classes.margin,
                                      classes.textField
                                    )}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      name="doc_Desc"
                                      placeholder="Name in ID"
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      labelWidth={0}
                                      className={classes.searchInput}
                                      defaultValue={inputFieldsNOC.document_description}
                                      onChange={(event) =>
                                        handleNOCDesc(event, index)
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                &nbsp;&nbsp;
                              </Grid>
                            ))}
                          </Grid>
                          <br />
                          <Grid>
                            <div className="bag-info">
                              <span>
                                {" "}
                                <svg
                                  id="tick-circle"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                >
                                  <path
                                    id="Path_658"
                                    data-name="Path 658"
                                    d="M0,7.5A7.5,7.5,0,1,1,7.5,15,7.5,7.5,0,0,1,0,7.5Zm7.072,3.21,4.318-5.4-.78-.624-3.682,4.6L4.32,7.116l-.64.768,3.392,2.827Z"
                                    fill="#61b768"
                                    fill-rule="evenodd"
                                  />
                                </svg>
                              </span>
                              <span className="bag-details">
                              Please include all orginals to be tracked in this bag
                              </span>
                            </div>
                          </Grid>
                          <br />
                          <br />
                          <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                              <span className="client">
                                <b>Client Details</b>
                              </span>
                            </Grid>
                            <Grid
                              className="dflex"
                              item
                              xs={12}
                              md={12}
                              lg={12}
                            >
                              <Grid item xs={12} md={3} lg={3}>
                                <div className="client-head">Name</div>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    name="name"
                                    placeholder="Name"
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                    onChange={(event) => handleClientName(event)}
                                    labelWidth={0}
                                    className={classes.searchInput}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                <div className="client-head">Phone</div>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    name="mobileNo"
                                    type="number"
                                    placeholder="Phone number"
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                    onChange={(event) => handleClientPhone(event)}
                                    labelWidth={0}
                                    className={classes.searchInput}
                                  />
                                </FormControl>
                              </Grid>
                              {/* <Grid item xs={12} md={6} lg={6}>
                                <div className="client-head">Address</div>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                  <PlacesAutocomplete
                                    value={address}
                                    onChange={setAddress}
                                    onSelect={handleSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div>
                                        <OutlinedInput
                                          id="outlined-adornment-weight"
                                          placeholder="Address"
                                          fullWidth
                                          name="address"
                                          startAdornment={
                                            <InputAdornment position="start">
                                              {" "}
                                              <img src="./Images/loc1.svg"></img>
                                            </InputAdornment>
                                          }
                                          aria-describedby="outlined-weight-helper-text"
                                          {...getInputProps({
                                            placeholder: "Search Places ...",
                                            className: "location-search-input",
                                          })}
                                          labelWidth={0}
                                          className={classes.searchInput}
                                        />

                                        <div>
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map((suggestion) => {
                                            const className = suggestion.active
                                              ? "suggestion-item--active"
                                              : "suggestion-item";
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                              ? {
                                                  backgroundColor: "#fafafa",
                                                  cursor: "pointer",
                                                  padding: "10px",
                                                }
                                              : {
                                                  backgroundColor: "#ffffff",
                                                  cursor: "pointer",
                                                  padding: "10px",
                                                };
                                            return (
                                              <div
                                                {...getSuggestionItemProps(
                                                  suggestion,
                                                  {
                                                    className,
                                                    style,
                                                  }
                                                )}
                                              >
                                                <span>
                                                  {suggestion.description}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </FormControl>
                              </Grid> */}
                            </Grid>
                            <Grid
                              className="dflex1"
                              item
                              xs={12}
                              md={12}
                              lg={12}
                            >
                              <Grid item xs={12} md={3} lg={3}>
                                <div className="client-head">
                                  IoT Device Number
                                </div>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                   <Select
                                    displayEmpty
                                    className={classes.searchInput}
                                    id="outlined-adornment-weight"
                                    renderValue={(selected) => {
                                      if (selected && selected.length === 0) {
                                        return (
                                          <span className={classes.photoQty}>
                                            Iot device id
                                          </span>
                                        );
                                      }

                                      return deviceNumber;
                                    }}
                                    value={deviceNumber}
                                    onChange={handleClientDevice}
                                  >
                                      
                                    <MenuItem disabled value="">
                                      Iot device name
                                    </MenuItem>
                                 
                                    {iotDetails.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        <div>
                                          <span>{name.device_name}</span>&nbsp;&nbsp;
                                        </div>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {/* <OutlinedInput
                                    id="outlined-adornment-weight"
                                    name="iotDevice"
                                    placeholder="Enter IoT no."
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                    onChange={(event) => handleClientDevice(event)}
                                    labelWidth={0}
                                    className={classes.searchInput}
                                  /> */}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                <div className="client-head">
                                  @Tag Document ID to
                                </div>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                >
                                  <Select
                                    displayEmpty
                                    className={classes.searchInput}
                                    id="outlined-adornment-weight"
                                    renderValue={(selected) => {
                                      if (selected && selected.length === 0) {
                                        return (
                                          <span className={classes.photoQty}>
                                            Staff
                                          </span>
                                        );
                                      }

                                      return staffNames;
                                    }}
                                    value={staffNames}
                                    onChange={handleChangestaffNames}
                                  >
                                    <MenuItem disabled value="">
                                      Staff
                                    </MenuItem>
                                    {staffSet.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        <div>
                                          <span>{name.name}</span>&nbsp;&nbsp;
                                        </div>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container className="dflex1">
                              <Grid item xs={12} md={12} lg={12}>
                                <FormControl
                                  fullWidth
                                  className={clsx(
                                    classes.margin,
                                    classes.textField
                                  )}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    name="comments"
                                    placeholder="Please share any comments here…"
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                    className={classes.searchInput}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                            {!submitted ? (
                            <Grid item xs={12} md={12} lg={12}>
                              <div className={classes.content}>
                                <Grid container spacing={3}>
                                  <Grid item md={3} lg={3}></Grid>
                                  <Grid
                                    item
                                    xs={10}
                                    md={6}
                                    lg={6}
                                    className="flexed"
                                  >
                                    <button
                                    onClick={resetDocument}
                                      className={classes.service_btnReset}
                                    >
                                      Reset
                                    </button>
                                    <button
                                      className={classes.service_btn}
                                      onClick={submitDocument}
                                    >
                                      Submit
                                    </button>
                                  </Grid>
                                  <Grid item md={3} lg={3}></Grid>
                                </Grid>
                              </div>
                            </Grid>):(
                               <Grid item xs={12} md={12} lg={12}>
                               <div className={classes.content}>
                                 <Grid container spacing={3}>
                                   <Grid item md={3} lg={3}></Grid>
                                   <Grid
                                     item
                                     xs={10}
                                     md={6}
                                     lg={6}
                                     className="flexed"
                                   >
                                     
                                     <button
                                       className={classes.service_btn}
                                       
                                     >
                                       Submitted Successfully
                                     </button>
                                   </Grid>
                                   <Grid item md={3} lg={3}></Grid>
                                 </Grid>
                               </div>
                             </Grid>
                            )}
                          </Grid>
                        </Box>
                      </TabPanel>
                      {/* <TabPanel value={2}></TabPanel>
                      <TabPanel value={3}></TabPanel> */}
                      <TabPanel value={2}>
                        <Grid container xs={12} md={12} lg={12}>
                          <IotConfiguration></IotConfiguration>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={3}>
                        <CompletedDocuments></CompletedDocuments>
                      </TabPanel>
                    </TabsUnstyled>

                    {/*}  <Tabs
                      className="tabs"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab className="tab-section" label="Item One" />
                      &nbsp;
                      <Tab label="Item Two" />
                      <Tab label="Item Three" />
                    </Tabs>*/}
                  </Box>
                </Grid>
              </Grid>
              ):(
                <div className='center2'>
                        <div class="loader"></div>
                        </div>)}
            </Grid>
            <Grid item md={12} lg={12}>
              <div className="footed">
                <Footer></Footer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(SendPackageStyle)(Documenttracker);
