import { baseAxios } from './index';

const getBaseURL = () => {
  try {
    let baseURL = '';


    baseURL = 'https://apis.staging.jiffy.ae'
    if (process.env.NODE_ENV === 'development') {
      baseURL = 'https://apis.staging.jiffy.ae'
    }

    if (process.env.NODE_ENV === 'production') {
      baseURL = 'https://apis.staging.jiffy.ae';
    }
    return baseURL;
  } catch (error) {
  console.log(error);
  }
};
const getIotUrl = () => {
   let iotUrls = 'https://5p6dqwllr6.execute-api.ap-south-1.amazonaws.com/staging'
 // let iotUrls = 'http://65.109.142.7:8000'
  return iotUrls;
}

const getDashboardDetails = async (userId,companyId)=> {
  let baseURL = getBaseURL();
  return baseAxios({
    method: 'get',
    url: `${baseURL}/vendor/api/v1/dashboard/?userId=${userId}&companyId=${companyId}`,
  });
};
const getDashboardDetailsFilter = async (userId,end) => {
  let baseURL = getBaseURL();
  return baseAxios({
    method: 'get',
    url: `${baseURL}/vendor/api/v1/dashboard/?userId=${userId}&from_date=${end}`,
  });
};
function loginAPI(payload) {
  return baseAxios({
    method: 'post',
    url: `/login`,
    data: payload,
  });
}

function sendPackage(payload) {
  return baseAxios({
    method: 'get',
    url: `/parcel`,
    data: payload,
  });
}

function deleteAPI(payload) {
  return baseAxios({
    method: 'delete',
    url: `apikeys/`,
    data: payload,
  });
}

function revokeAPI(payload) {
  return baseAxios({
    method: 'delete',
    url: `apikeys/`,
    data: payload,
  });
}

function regenerateAPI(payload) {
  return baseAxios({
    method: 'put',
    url: `apikeys/`,
    data: payload,
  });
}

function editAPI(payload) {
  return baseAxios({
    method: 'put',
    url: `apikeys/`,
    data: payload,
  });
}

function getCountry(payload) {
  return baseAxios({
    method: 'get',
    url: `baseinfo/`,
    params: payload,
  });
}

export { getBaseURL,getIotUrl,getDashboardDetailsFilter,loginAPI, sendPackage, deleteAPI, regenerateAPI, editAPI, revokeAPI, getCountry, getDashboardDetails };
